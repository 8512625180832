<template>
  <div data-app v-if="page.id">
    <v-row>
      <v-col md="9" offset-xl="2" xl="6">
        <div class="card card-custom card-stretch">
          <div class="card-body">
            <v-row class="mt-5">
              <v-col cols="8">
                <label>{{ page.page_title }}</label>
              </v-col>
            </v-row>

            <Chapter
              :chapters="page.chapters"
              :pageId="page.id"
              :addable="false"
              :creatable="false"
              :deletable="false"
              @change="fetchPage"
            />
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module";
import { FIND_BY_PATH } from "@/core/services/store/pages.module";

import { mapActions } from "vuex";

import Chapter from "@/view/components/Chapter.vue";
// import TextBlock from "@/view/components/Block/TextBlock.vue";

export default {
  components: {
    Chapter,
    // TextBlock,
  },

  data() {
      return {
          path: this.$route.params.path || null,
          page: {},
      }
  },

  mounted() {
    this.fetchPage();
  },
  
  watch: {
    $route() {
      this.path = this.$route.params.path || null;
      this.page = {};
      this.fetchPage()
    }
  },

  methods: {
    ...mapActions("snackbar", ["showSnack"]),

    showLoading() {
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
    },

    hideLoading() {
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    },

    fetchPage() {
      this.showLoading();
      this.$store.dispatch(FIND_BY_PATH, { path: this.path }).then((data) => {
        if (data.page != null) {
          this.page = data.page;
        }
        this.hideLoading();
      });
    },
  },
};
</script>
